import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

export default function Template({
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
    post,
  },
}) {
  return (
    <Layout>
      <Helmet title={`${siteTitle} - ${post.frontmatter.title}`} />
      <div id="pageheader">
        <h1>{post.frontmatter.title}</h1>
        <span>{post.frontmatter.subtitle}</span>
      </div>
      <div
        className="blog-post-content"
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageByPath($path: String!) {
    post: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        subtitle
      }
    }
    site: site {
      siteMetadata {
        title
      }
    }
  }
`
